import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Box, Anchor, ResponsiveContext, Button, Layer } from 'grommet';
import { Menu } from 'grommet-icons';
import { InternalLink } from '../internal/internal';

const Header = (props) => {
  const [isOpenedMobileMenu, setIsOpenedMobileMenu] = useState(false);
  const openCloseMobileMenu = () => {
    setIsOpenedMobileMenu(!isOpenedMobileMenu);
  };
  const Settings = () => {
    if (props.isOpenPage) {
      return (
        <ResponsiveContext.Consumer>
          {(size) => {
            if (size !== 'xsmall' && size !== 'small') {
              return (
                <Box
                  style={{ position: 'absolute', right: '3rem' }}
                  align="center"
                  justify="center"
                >
                  <a
                    align={'right'}
                    href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box direction="row">
                      <StaticImage
                        width={25}
                        objectFit="contain"
                        src="../../icons/inquiry.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'お問い合わせ'}
                        imgClassName="ofi"
                      />
                      <Anchor
                        color="#000000"
                        as="span"
                        label="お問い合わせ"
                        style={{ marginLeft: '4px', fontWeight: 'inherit' }}
                      />
                    </Box>
                  </a>
                </Box>
              );
            } else
              return (
                <Box border={{ side: 'top' }} margin={{ top: 'large' }} pad={{ top: 'large' }}>
                  <a
                    align={'right'}
                    href="/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box direction="row">
                      <StaticImage
                        width={25}
                        objectFit="contain"
                        src="../../icons/inquiry.png"
                        formats={['auto', 'webp', 'avif']}
                        alt={'お問い合わせ'}
                        imgClassName="ofi"
                      />
                      <Anchor
                        color="#000000"
                        as="span"
                        label="お問い合わせ"
                        style={{ marginLeft: '4px', fontWeight: 'inherit' }}
                      />
                    </Box>
                  </a>
                </Box>
              );
          }}
        </ResponsiveContext.Consumer>
      );
    } else {
      return '';
    }
  };

  const MobileHeader = (props) => {
    return (
      <Layer position="top" full="vertical" modal={true} plain={true}>
        <Box background={'#ffffff'} fill="vertical" align="center" gap="large" pad="large">
          <Button onClick={openCloseMobileMenu}>
            <span style={{ fontSize: '1.5rem' }}>×</span>
          </Button>
          {props.headerMenu.map((item, index) => (
            <InternalLink key={index} to={item.path}>
              <Anchor
                color="#000000"
                as="span"
                label={item.label}
                style={{ fontWeight: 'inherit' }}
              ></Anchor>
            </InternalLink>
          ))}
          {/* <Settings /> */}
        </Box>
      </Layer>
    );
  };
  let headerPadding = '0.5rem 0rem 0.5rem 3rem';

  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          headerPadding = '0.5rem 0rem 0.5rem 3rem';
        } else {
          headerPadding = '0.5rem 0rem 0.5rem 0rem';
        }
        return (
          <Box
            as="header"
            direction="row"
            background="linear-gradient(-45deg, #f4f5f7, #b2b2b2)"
            margin="0 0 1.45rem"
            pad={headerPadding}
            height="75px"
            align="center"
            justify="center"
          >
            <ResponsiveContext.Consumer gap="medium" align="center" justify="around">
              {(size) => {
                if (size !== 'xsmall' && size !== 'small') {
                  return (
                    <Box direction="row">
                      <InternalLink to="/">
                        <Anchor as="span" style={{ textDecoration: 'none', boxShadow: 'none' }}>
                          <StaticImage
                            objectFit="contain"
                            src="../../images/FORKLORE.png"
                            height={90}
                            formats={['auto', 'webp', 'avif']}
                            alt={props.siteTitle}
                            imgClassName="ofi"
                          />
                        </Anchor>
                      </InternalLink>
                      <Box
                        direction="row"
                        margin={'0 0 0 3vw'}
                        style={{ marginRight: '20%', zIndex: 999, width: '100%' }}
                      >
                        <Box
                          as="nav"
                          gap="medium"
                          direction="row"
                          align="top"
                          pad="1rem 0 0 0"
                          style={{ width: '100%' }}
                        >
                          {props.headerMenu.map((item, index) => (
                            <Box key={'header-' + index} align="center">
                              <InternalLink key={index} to={item.path}>
                                <Anchor
                                  color="#000000"
                                  as="span"
                                  margin={'0 0 0 5rem'}
                                  label={item.label}
                                  style={{ fontWeight: 'inherit', whiteSpace: 'nowrap' }}
                                />
                              </InternalLink>
                            </Box>
                          ))}
                          {/* <Settings /> */}
                        </Box>
                      </Box>
                    </Box>
                  );
                } else {
                  return (
                    <>
                      <Button
                        onClick={openCloseMobileMenu}
                        icon={<Menu />}
                        style={{ position: 'absolute', left: 40 }}
                      />
                      <InternalLink to="/">
                        <Anchor as="span" style={{ textDecoration: 'none', boxShadow: 'none' }}>
                          <StaticImage
                            objectFit="contain"
                            src="../../images/FORKLORE.png"
                            height={75}
                            formats={['auto', 'webp', 'avif']}
                            alt={props.siteTitle}
                            imgClassName="ofi"
                          />
                        </Anchor>
                      </InternalLink>
                      <Box>
                        {isOpenedMobileMenu ? <MobileHeader headerMenu={props.headerMenu} /> : ''}
                      </Box>
                    </>
                  );
                }
              }}
            </ResponsiveContext.Consumer>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
};

export default Header;
