import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Box, ResponsiveContext, Accordion, AccordionPanel } from 'grommet';

const FooterBottom = styled(Box)`
  margin-top: 24px;
  padding: 8px;
  position: relative;
  font-size: 14px;
`;

const FooterBottomLogo = styled(Box)`
  float: left;
  bottom: 0;
  position: absolute;
`;

const FooterBottomContent = styled(Box)`
  float: right;
  text-align: right;
`;

const Footer = (props) => (
  <footer
    style={{
      backgroundColor: `#DDDDDD`,
      marginTop: `2rem`,
      padding: `1.5rem`,
      display: `block`,
    }}
  >
    <ResponsiveContext.Consumer gap="medium" align="center" justify="around">
      {(size) => {
        if (size !== 'xsmall' && size !== 'small') {
          return (
            <Box direction="row" margin={'0 0 0 1.5rem'} style={{ lineHeight: '1.8' }}>
              <Box style={{ flexBasis: '33%' }}>
                {props.footerLeftMenu.map((footerMenu, index) => (
                  <Box key={'left_' + index} style={{ marginBottom: '1rem' }}>
                    <strong style={{ fontSize: '1.1rem' }}>{footerMenu.title}</strong>
                    {footerMenu.item.map((item, itemIndex) => {
                      if (item.isOuterLink) {
                        return (
                          <a
                            className="linkblack"
                            key={'left_' + itemIndex}
                            href={item.path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span
                              style={{ color: '#000000', marginLeft: '5px', fontWeight: 'inherit' }}
                            >
                              {item.label}
                            </span>
                          </a>
                        );
                      } else {
                        return (
                          <a className="linkblack" key={'left_' + itemIndex} href={item.path}>
                            <span
                              style={{ color: '#000000', marginLeft: '5px', fontWeight: 'inherit' }}
                            >
                              {item.label}
                            </span>
                          </a>
                        );
                      }
                    })}
                  </Box>
                ))}
              </Box>
              {props.isOpenPage ? (
                <Box style={{ flexBasis: '33%' }}>
                  {props.footerCenterMenu.map((footerMenu, index) => (
                    <Box key={'center_' + index} style={{ marginBottom: '1rem' }}>
                      <strong style={{ fontSize: '1.1rem' }}>{footerMenu.title}</strong>
                      {footerMenu.item.map((item, itemIndex) => {
                        if (item.isOuterLink) {
                          return (
                            <a
                              className="linkblack"
                              key={'center_' + itemIndex}
                              href={item.path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span
                                style={{
                                  color: '#000000',
                                  marginLeft: '5px',
                                  fontWeight: 'inherit',
                                }}
                              >
                                {item.label}
                              </span>
                            </a>
                          );
                        } else {
                          return (
                            <a className="linkblack" key={'center_' + itemIndex} href={item.path}>
                              <span
                                style={{
                                  color: '#000000',
                                  marginLeft: '5px',
                                  fontWeight: 'inherit',
                                }}
                              >
                                {item.label}
                              </span>
                            </a>
                          );
                        }
                      })}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box style={{ flexBasis: '33%' }}></Box>
              )}
              <Box style={{ flexBasis: '33%' }}>
                <Box style={{ marginBottom: '1rem' }}>
                  <strong style={{ fontSize: '1.1rem' }}>FORKLOREについて</strong>
                  <a className="linkblack" href="/privacy">
                    <span style={{ color: '#000000', marginLeft: '5px', fontWeight: 'inherit' }}>
                      情報セキュリティ
                    </span>
                  </a>
                  <a className="linkblack" href="http://www.toyota-lf.com/privacypolicy/">
                    <Box>
                      <span style={{ marginLeft: '5px', fontWeight: 'inherit' }}>
                        個人情報保護方針
                      </span>
                      <span style={{ fontSize: '5px' }}>{'(豊田自動織機トヨタL&Fカンパニー)'}</span>
                    </Box>
                  </a>
                  <a className="linkblack" href="https://www.toyota-shokki.co.jp/">
                    <Box>
                      <span style={{ color: '#000000', marginLeft: '5px', fontWeight: 'inherit' }}>
                        企業情報
                      </span>
                      <span className="linkblack" style={{ fontSize: '5px' }}>
                        {'(豊田自動織機HP)'}
                      </span>
                    </Box>
                  </a>
                  {props.isOpenPage ? (
                    <>
                      <strong style={{ marginTop: '1rem', fontSize: '1.1rem' }}>
                        外部公開サイト
                      </strong>
                      <a
                        href="https://www.youtube.com/channel/UC0DEZelqP60dK8vEWDqy_yg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StaticImage
                          objectFit="contain"
                          src="../../icons/youtube.png"
                          width={100}
                          formats={['auto', 'webp', 'avif']}
                          alt={'YouTube'}
                          imgClassName="ofi"
                        />
                      </a>
                    </>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
          );
        } else {
          return (
            <>
              <Accordion multiple={true}>
                {props.footerLeftMenu.map((footerMenu, index) => (
                  <Box key={'footer-left-' + index} style={{ marginBottom: '0.5rem' }}>
                    <AccordionPanel
                      className="accordionPanel"
                      label={footerMenu.title}
                      key={'Left_' + index}
                      border="light"
                    >
                      <Box pad="medium" border="light">
                        {footerMenu.item.map((item, itemIndex) => {
                          if (item.isOuterLink) {
                            return (
                              <a
                                key={'Left_' + itemIndex}
                                href={item.path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span
                                  style={{
                                    color: '#000000',
                                    marginLeft: '5px',
                                    fontWeight: 'inherit',
                                  }}
                                >
                                  {item.label}
                                </span>
                              </a>
                            );
                          } else {
                            return (
                              <a key={'Left_' + itemIndex} href={item.path}>
                                <span
                                  style={{
                                    color: '#000000',
                                    marginLeft: '5px',
                                    fontWeight: 'inherit',
                                  }}
                                >
                                  {item.label}
                                </span>
                              </a>
                            );
                          }
                        })}
                      </Box>
                    </AccordionPanel>
                  </Box>
                ))}
                {props.isOpenPage ? (
                  <>
                    {props.footerCenterMenu.map((footerMenu, index) => (
                      <Box key={'footer-center-' + index} style={{ marginBottom: '0.5rem' }}>
                        <AccordionPanel
                          className="accordionPanel"
                          label={footerMenu.title}
                          key={'Center_' + index}
                          border="light"
                        >
                          <Box pad="medium" border="light">
                            {footerMenu.item.map((item, itemIndex) => {
                              if (item.isOuterLink) {
                                return (
                                  <a
                                    key={'Center_' + itemIndex}
                                    href={item.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span
                                      style={{
                                        color: '#000000',
                                        marginLeft: '5px',
                                        fontWeight: 'inherit',
                                      }}
                                    >
                                      {item.label}
                                    </span>
                                  </a>
                                );
                              } else {
                                return (
                                  <a key={'Center_' + itemIndex} href={item.path}>
                                    <span
                                      style={{
                                        color: '#000000',
                                        marginLeft: '5px',
                                        fontWeight: 'inherit',
                                      }}
                                    >
                                      {item.label}
                                    </span>
                                  </a>
                                );
                              }
                            })}
                          </Box>
                        </AccordionPanel>
                      </Box>
                    ))}
                  </>
                ) : (
                  ''
                )}
                <Box style={{ marginBottom: '0.5rem' }}>
                  <AccordionPanel
                    className="accordionPanel"
                    label={'FORKLOREについて'}
                    border="light"
                  >
                    <Box pad="medium" border="light">
                      <a href="/privacy">
                        <span
                          style={{ color: '#000000', marginLeft: '5px', fontWeight: 'inherit' }}
                        >
                          情報セキュリティ
                        </span>
                      </a>
                      <a href="http://www.toyota-lf.com/privacypolicy/">
                        <span
                          style={{ color: '#000000', marginLeft: '5px', fontWeight: 'inherit' }}
                        >
                          個人情報保護方針
                          <span style={{ fontSize: '5px' }}>
                            {'(豊田自動織機トヨタL&Fカンパニー)'}
                          </span>
                        </span>
                      </a>
                      <a href="https://www.toyota-shokki.co.jp/">
                        <span
                          style={{ color: '#000000', marginLeft: '5px', fontWeight: 'inherit' }}
                        >
                          企業情報
                          <span style={{ fontSize: '5px' }}>{'(豊田自動織機HP)'}</span>
                        </span>
                      </a>
                    </Box>
                  </AccordionPanel>
                </Box>
              </Accordion>
              {props.isOpenPage ? (
                <a
                  href="https://www.youtube.com/channel/UC0DEZelqP60dK8vEWDqy_yg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StaticImage
                    objectFit="contain"
                    src="../../icons/youtube.png"
                    width={100}
                    formats={['auto', 'webp', 'avif']}
                    alt={'YouTube'}
                    imgClassName="ofi"
                  />
                </a>
              ) : (
                ''
              )}
            </>
          );
        }
      }}
    </ResponsiveContext.Consumer>
    <FooterBottom>
      <FooterBottomLogo>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <StaticImage
            objectFit="contain"
            src="../../images/FORKLORE.png"
            width={100}
            formats={['auto', 'webp', 'avif']}
            alt={'FORKLORE'}
            imgClassName="ofi"
          />
        </Link>
      </FooterBottomLogo>
      <FooterBottomContent>
        <span>
          © {new Date().getFullYear()}
          {' FORKLORE.'}
        </span>
      </FooterBottomContent>
    </FooterBottom>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: `FORKLORE`,
};

export default Footer;
