import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import './layout.css';
import { deepMerge } from 'grommet/utils';
import { grommet } from 'grommet/themes';
import { Grommet } from 'grommet';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import objectFitImages from 'object-fit-images';
import { useLocation } from '@reach/router';

const customBreakpoints = deepMerge(grommet, {
  global: {
    font: {
      family:
        '游ゴシック Medium, Yu Gothic Medium, 游ゴシック, Yu Gothic, Meiryo, Hiragino Kaku Gothic ProN, Hiragino Sans, sans-serif',
    },
    colors: { doc: '#000000' },
    breakpoints: {
      small: {
        value: 1024,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '4px',
          large: '6px',
          xlarge: '12px',
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '3px',
          small: '6px',
          medium: '12px',
          large: '24px',
          xlarge: '48px',
        },
        size: {
          xxsmall: '24px',
          xsmall: '48px',
          small: '96px',
          medium: '192px',
          large: '384px',
          xlarge: '768px',
          full: '100%',
        },
      },
      medium: { value: 1536 },
      large: {},
    },
  },
});

const PAGE_NAME_VR = 'VRソリューション';
const Layout = ({ children }) => {
  const uri = useLocation().origin;
  const prodUri = 'https://info.forklore.jp';

  let isOpenPage = true;
  if (children) {
    for (let i = 0; i < children.length; i++) {
      if (children[i].props?.title === PAGE_NAME_VR) {
        isOpenPage = false;
        break;
      }
    }
  }

  let gaTag;
  if (uri === prodUri) {
    gaTag = 'G-8F4770S7Q3';
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          logo
          menu {
            active
            label
            type
            path
          }
          footerLeft {
            title
            item {
              active
              label
              type
              path
              isOuterLink
            }
          }
          footerCenter {
            title
            item {
              active
              label
              type
              path
              isOuterLink
            }
          }
        }
      }
    }
  `);
  const [isButtonActive, setIsButtonActive] = useState(false);
  useEffect(() => {
    objectFitImages();
    window.addEventListener('scroll', scrollWindow);
    return () => {
      window.removeEventListener('scroll', scrollWindow);
    };
  }, []);
  const scrollWindow = () => {
    const top = 100; //ボタンを表示させたい位置
    let scroll = 0;
    scroll = window.scrollY;
    if (top <= scroll) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }
  };

  const escape_html = (string) => {
    if (typeof string !== 'string') {
      return string;
    }
    return string.replace(/[&'`"<>]/g, function (match) {
      return {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
      }[match];
    });
  };
  return (
    <>
      {gaTag ? (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${escape_html(gaTag)}`}
          ></script>
          <script>
            {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${escape_html(gaTag)}');
  `}
          </script>
        </Helmet>
      ) : (
        ''
      )}
      <Grommet theme={customBreakpoints} color="#000000">
        <Header
          siteTitle={data.site.siteMetadata?.title || `FORKLORE`}
          logo={data.site.siteMetadata?.logo || null}
          headerMenu={data.site.siteMetadata?.menu || []}
          isOpenPage={isOpenPage}
        />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: `1536px`,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          {isButtonActive ? (
            <div>
              <a href="#" className="pagetop" style={{ display: 'block' }}>
                <StaticImage
                  objectFit="contain"
                  objectPosition="center center"
                  src="../../icons/baseline_keyboard_double_arrow_up_black_48dp.png"
                  formats={['auto', 'webp', 'avif']}
                  alt={'トップに戻る'}
                  width={45}
                  height={45}
                  imgClassName="ofi"
                />
              </a>
            </div>
          ) : (
            ''
          )}
          <main>{children}</main>
          <Footer
            footerLeftMenu={data.site.siteMetadata?.footerLeft}
            footerCenterMenu={data.site.siteMetadata?.footerCenter}
            footerRightMenu={data.site.siteMetadata?.footerRight}
            isOpenPage={isOpenPage}
          />
        </div>
      </Grommet>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
